import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box } from '@mui/material';
import theme from './theme';
import Header from './components/Header';
import JoinMeeting from './components/JoinMeeting';
import Features from './components/Features';
import Footer from './components/Footer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Logout from './components/Logout';
import { AuthProvider } from './components/AuthContext';
import MeetingRoom from './components/MeetingRoom';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            {/* Home Route */}
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                    }}
                  >
                    {/* JoinMeeting always at the top */}
                    <Box sx={{ width: '100%' }}>
                      <JoinMeeting />
                    </Box>

                    {/* Centered Features */}
                    <Box
                      sx={{
                        flex: '1 1 auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Features />
                    </Box>

                    {/* Footer at the bottom */}
                    <Footer />
                  </Box>
                </>
              }
            />

            {/* Meeting Room Route */}
            <Route path="/meeting/:roomName" element={<MeetingRoom />} />

            {/* Auth Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;