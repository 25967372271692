import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');

  // Fetch CSRF Token when the app loads
  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get('https://langmeet.ai/backend/api/get-csrf/', { withCredentials: true });
        setCsrfToken(response.data.csrfToken); // Save the CSRF token
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    getCsrfToken();
  }, []); // Empty dependency array, runs only on mount

  // Check authentication status when the CSRF token is available
  useEffect(() => {
    if (csrfToken) {
      const checkAuthStatus = async () => {
        try {
          const response = await axios.get('https://langmeet.ai/backend/api/check-auth/', {
            headers: {
              'X-CSRFToken': csrfToken, // Include the CSRF token in the request headers
            },
            withCredentials: true,
          });
          setIsAuthenticated(response.data.isAuthenticated);
        } catch (error) {
          console.error('Error checking auth status:', error);
          setIsAuthenticated(false);
        }
      };

      checkAuthStatus();
    }
  }, [csrfToken]); // Add csrfToken as a dependency

  const login = () => setIsAuthenticated(true);
  const logout = () => setIsAuthenticated(false);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
