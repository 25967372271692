import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import '@livekit/components-styles';
import axios from 'axios';
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
} from '@livekit/components-react';

import { Track } from 'livekit-client';

const MeetingRoom = () => {
  const { roomName } = useParams(); // Retrieve room name from URL
  const location = useLocation();
  const token = location.state?.token;
  const serverUrl = location.state?.serverUrl;

  console.log('token: ', token);
  console.log('serverUrl: ', serverUrl);

  if (!token || !serverUrl) {
    return <div>Error: Unable to join the room. Please try again.</div>;
  }

  return (
    <LiveKitRoom
      video={true}
      audio={true}
      token={token}
      serverUrl={serverUrl}
      data-lk-theme="default"
      style={{ height: '100vh' }}
    >
      <h1>Meeting Room: {roomName}</h1>
      {/* Your custom component with basic video conferencing functionality. */}
      <MyVideoConference />
      {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
      <RoomAudioRenderer />
      {/* Controls for the user to start/stop audio, video, and screen
      share tracks and to leave the room. */}
      <ControlBar />
    </LiveKitRoom>
  );
};

function MyVideoConference() {
  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  return (
    <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
      {/* The GridLayout accepts zero or one child. The child is used
      as a template to render all passed in tracks. */}
      <ParticipantTile />
    </GridLayout>
  );
}

export default MeetingRoom;

export const joinMeeting = async (csrfToken, setToken, setServerUrl, setError, room_name) => {
  try {
    const response = await axios.post(
      'https://langmeet.ai/backend/api/get_meeting_token/',
      {
        room_name: room_name,
      },
      {
        headers: { 'X-CSRFToken': csrfToken },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      const data = response.data;
      setToken(data.token);
      setServerUrl(data.url);
      return data;
    } else {
      setError('Failed to start the meeting. Please try again.');
      return false;
    }
  } catch (error) {
    console.error('Error starting meeting:', error);
    setError('Something went wrong. Please try again.');
    return false;
  }
};
