// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ p: 4, textAlign: 'center', bgcolor: 'primary.dark', color: 'white' }}>
      <Typography variant="body2" gutterBottom>
        © 2024 LangMeet.AI. All rights reserved.
      </Typography>
      <Link href="#" color="inherit" sx={{ mx: 1 }}>
        Privacy Policy
      </Link>
      <Link href="#" color="inherit" sx={{ mx: 1 }}>
        Terms of Service
      </Link>
    </Box>
  );
};

export default Footer;