import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Logout = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const navigate = useNavigate();
  const { logout } = useAuth();

  // Fetch CSRF token when the component mounts
  useEffect(() => {
    const getCsrfToken = async () => {
      try {
        const response = await axios.get('https://langmeet.ai/backend/api/get-csrf/', { withCredentials: true });
        setCsrfToken(response.data.csrfToken); // Save the CSRF token
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    getCsrfToken();
  }, []);

  const handleCancel = async () => {
    navigate('/');
}

  const handleLogout = async () => {
    setErrorMessage('');
    
    try {
      // Send POST request to the    backend's logout endpoint
      await axios.post('https://langmeet.ai/backend/api/logout/', {}, {
        headers: {
          'X-CSRFToken': csrfToken, // Include the CSRF token in the request headers
        },
        withCredentials: true, // Include credentials (for session management)
      });
      
      logout();
      // Redirect to the home  page after successful logout
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.error || 'Logout failed');
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '2rem auto', padding: '2rem', border: '1px solid #ccc', borderRadius: '8px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Are you sure you want to logout?
      </Typography>
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      <Button variant="contained" color="error" fullWidth sx={{ mt: 2 }} onClick={handleLogout}>
        Logout
      </Button>
      <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={handleCancel}>
        Cancel
      </Button>
    </Box>
  );
};

export default Logout;
