// src/components/Features.js
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import VideoCallIcon from '@mui/icons-material/VideoCall';

const features = [
  { icon: <LanguageIcon fontSize="large" />, title: 'Multi-Language Support', description: 'Communicate in your preferred language.' },
  { icon: <TranslateIcon fontSize="large" />, title: 'Live Translation', description: 'Real-time translation for seamless conversation.' },
  { icon: <VideoCallIcon fontSize="large" />, title: 'Easy to Use', description: 'Simple and intuitive interface.' },
];

const Features = () => {
  return (
    <Box sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Features
      </Typography>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={4} key={index}>
            {feature.icon}
            <Typography variant="h6">{feature.title}</Typography>
            <Typography variant="body1">{feature.description}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;